/**
 * The current module provides the necessary methods for saving and retrieving the API access token and any associated information.
 * The `apiToken` variable, as well as any information derived from it, is protected by the closure of this module.
 */
import jwtDecode from 'jwt-decode';
import { TokenPayload } from '../models/TokenPayload';

interface ApiTokenWithExpiry {
  token: string;
  expiresAt: number;
}

/**
 * we keep this key for recoil consistency
 */
const STORAGE_KEY = btoa('Global/ApiToken');
const THREE_HOURS = 3 * 60 * 60 * 1000;

// local storage logic to check if the token is still valid and set it to null if it is not
const extractToken: () => string | null = () => {
  const apiTokenWithExpiry: ApiTokenWithExpiry = JSON.parse(localStorage.getItem(STORAGE_KEY) as string);

  if (apiTokenWithExpiry && Date.now() <= apiTokenWithExpiry.expiresAt) {
    return apiTokenWithExpiry.token;
  } else {
    localStorage.removeItem(STORAGE_KEY);
    return null;
  }
};

// the variable where the api token is stored.
let apiToken: string | null = extractToken();

// this variable contains the decoded information of the api token
let decodedApiTokenPayload: TokenPayload | null = null;

/**
 *
 */
export class ApiTokenError extends Error {
  constructor() {
    super('The provided API token is not valid');
  }
}

/**
 * Takes a string and performs a checks whether it is a valid token, as per the JWT standard.
 * If the token passes the validation checks, it is saved locally within this module in two variables: apiToken and decodedApiToken.
 * The former stores the token as it is, whereas the latter contains the decoded information of the token payload.
 * The function returns true when the token is valid or throw an error otherwise.
 *
 * This function is crucial in handling the token and its decoded information securely, all over the app.
 * @param token
 */
export const saveToken = (token: string) => {
  try {
    apiToken = token;
    decodedApiTokenPayload = jwtDecode(apiToken);

    const expirationTime = Date.now() + THREE_HOURS;
    const tokenWithExpiry = {
      token: apiToken,
      expiresAt: expirationTime,
    };

    localStorage.setItem(STORAGE_KEY, JSON.stringify(tokenWithExpiry));

    return true;
  } catch (error) {
    apiToken = null;
    decodedApiTokenPayload = null;
    throw new ApiTokenError();
  }
};

/**
 * Allows access the value of the apiToken variable, which is stored and secured in the closure of this module.
 * This function is the only way to access the apiToken value outside this module, as it is designed to prevent external changes.
 */
export const retrieveToken = () => {
  if (apiToken) return apiToken;
  const token = extractToken();

  if (token) {
    saveToken(token);
    return token;
  }

  return null;
};

/**
 * Allows access the value of the decodedApiToken variable, which is stored and secured in the closure of this module.
 * This function is the only way to access the decodedApiToken value outside this module, as it is designed to prevent external changes.
 */
export const retrieveDecodedToken = () => {
  if (decodedApiTokenPayload) return decodedApiTokenPayload;
  const token = extractToken();

  if (token) saveToken(token);

  return decodedApiTokenPayload as unknown as TokenPayload;
};

export const decode = (token: string): TokenPayload => {
  const decoded: TokenPayload = jwtDecode(token);
  const now = Date.now().valueOf() / 1000;
  if (decoded.exp && decoded.exp < now) {
    throw new Error(`Token expired`);
  } else if (decoded.nbf && decoded.nbf > now) {
    throw new Error(`Token cannot be used yet`);
  } else if (!decoded.jrn) {
    // Backwards compatability - can be removed after a period
    if ((decoded as any).e) {
      decoded.jrn = 'unsubscribe';
    } else {
      throw new Error(`Missing journey in token`);
    }
  } else if (!decoded.cfg) {
    throw new Error(`Missing config in token`);
  }
  return decoded;
};
