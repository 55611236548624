import { createSelector } from 'reselect';
import { RootState } from '../store';
import { decode } from '../../utils/token-utils';
import { handleError } from '@yumpingo/yummy-components/utils/error-handler';

const tokenRootState = (state: RootState) => state.token;

export const selectTokenState = createSelector(tokenRootState, (token) => token);

export const selectTokenDecoded = createSelector(selectTokenState, (token) => {
  if (token) {
    try {
      const decoded = decode(token);
      // We also put the apiToken into the object so we can ensure
      // we can compare which version we're looking at
      // This is to prevent a bug caused by recoil not
      // updating selectors immediately between renders
      return { ...decoded, token };
    } catch (error) {
      handleError(error);
    }
  }
});

export const selectTokenCustomDismenions = createSelector(selectTokenDecoded, (decodedToken) => {
  // This array needs to be the same order as the custom dimensions names set up in GA4
  // https://analytics.google.com/analytics/web/#/a81116848p377486503/admin/customdefinitions/hub
  return (
    (decodedToken && [
      decodedToken.vuid,
      decodedToken.meta?.cnl,
      decodedToken.meta?.lbl,
      decodedToken.ssid,
      decodedToken.blid ? (decodedToken.cfg?.flow === 'db' ? 'digiBill' : 'filtered') : 'standard',
      decodedToken.buid,
      decodedToken.cfg?.splash ? 'show splash' : 'skip splash',
    ]) ||
    null
  );
});

export const selectTokenConfig = createSelector(selectTokenDecoded, (decodedToken) => decodedToken && decodedToken.cfg);

export const selectTokenOnComplete = createSelector(selectTokenConfig, (config) => {
  // Returns the partner onComplete behaviour
  // Or here we configure the return to Central home screen if there are other links
  return config?.onComplete || (config?.links?.length ? { url: '/', delay: 3000 } : undefined);
});

export const selectTokenOnError = createSelector(selectTokenConfig, (config) => config?.onError);

export const selectTokenBrandName = createSelector(selectTokenConfig, (config) => {
  return (
    config &&
    (config.name ||
      // Backwards compatability with old unsubscribe tokens
      // Can be removed after a while
      (config as any).bname)
  );
});

export const selectTokenServiceStyleId = createSelector(selectTokenDecoded, (decodedToken) => decodedToken?.ssid);

export const selectTokenUserEmail = createSelector(selectTokenDecoded, (decodedToken) => {
  return (
    decodedToken &&
    (decodedToken.eml ||
      // Backwards compatability with old unsubscribe tokens
      // Can be removed after a while
      (decodedToken as any).e)
  );
});

export const selectTokenCampaignUid = createSelector(selectTokenDecoded, (decodedToken) => decodedToken?.cuid);
